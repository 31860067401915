// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import * as firebaseui from 'firebaseui';
import * as ciap from 'gcip-iap';

const jquery = require('jquery');
const $ = window.$ = window.jQuery = jquery;

let firebaseConfig;
if (process.env.NODE_ENV === 'development') {
    firebaseConfig = {
        apiKey: process.env.DEV_GOOGLE_API_KEY,
        authDomain: "auth.mssp.dev.jazzdev.io",
        databaseURL: "https://morkie-wolf-735333.firebaseio.com",
        projectId: "morkie-wolf-735333",
        storageBucket: "morkie-wolf-735333.appspot.com",
        messagingSenderId: "245703006291",
        appId: "1:245703006291:web:99394da9a59005d12ccea6"
    };

    // add review app
    let mrID = "";
    if (process.env.CI_MERGE_REQUEST_IID) {
        mrID = `data-merge-request-id='${process.env.CI_MERGE_REQUEST_IID}'`;
    }
    // add the visual review app
    $(function () {
        let wrapper = document.createElement('div');
        wrapper.innerHTML = `<script
          crossorigin="use-credentials"
          data-project-id='927'
          data-project-path='jazz/cloud-manager'
          ${mrID}
          data-mr-url='https://repo.jazzdev.io'
          data-require-auth='true'
          id='review-app-toolbar-script'
          src='/static/js/visual_review_toolbar.js'
      ></script>`;
        document.body.append(wrapper.firstChild);
    });
    console.log("running in development mode");
} else {
    let authDomain = "auth.mssp.ava.uk";
    if (location.hostname.endsWith(".qush.com")){
        authDomain = "auth.mssp.qush.com"
    }
    if (location.hostname.endsWith(".nextdlp.com")){
        authDomain = "auth.mssp.nextdlp.com"
    }
    firebaseConfig = {
        apiKey: process.env.PROD_GOOGLE_API_KEY,
        authDomain: authDomain,
        databaseURL: "https://jazz-cloud-manager-944092.firebaseio.com",
        projectId: "jazz-cloud-manager-944092",
        storageBucket: "jazz-cloud-manager-944092.appspot.com",
        messagingSenderId: "863712157118",
        appId: "1:863712157118:web:6df9b8edfae6f438bdedef"
    };
    console.log("SAML ACS URL: ", authDomain);
}

// redirect to https even if it's too late
if (location.protocol !== 'https:' && location.hostname !== "localhost") {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
    console.log("redirecting to https");
}
const requestURL = `${window.location.href}`;
// where this is redirecting to the MSSP portal, always redirect to the root
let redirectUrl;
if (window.location.hostname.startsWith("id.mssp.")) {
    let thisURL = new URL(requestURL);
    thisURL.hostname = thisURL.hostname.replace(/^(id\.)/, "");
    thisURL.pathname = "/";
    thisURL.search = "";
    redirectUrl = thisURL.toString();
    console.log("user to redirect upon sign in: ", redirectUrl);
}

$(document).ready(function () {
    let checkErrTimer = setInterval(errMsgChecker, 500);

    firebase.initializeApp(firebaseConfig);

    const URL_ERROR_MESSAGE_PARAM_KEY = 'message';
    const URL_DEFAULT_EMAIL_PARAM_KEY = 'email';
    const TENANT_STORAGE_KEY = 'tenant';
    const FB_STORAGE_KEY = 'firebaseui::rememberedAccounts';
    const FB_IDB_NAME = 'firebaseLocalStorageDb';
    const FB_IDB_OS_NAME = 'firebaseLocalStorage';
    const FB_IDB_KEY_PATH = 'fbase_key';
    const API_TenantName = 'authTenantName';
    const API_TenantId = 'authTenantId';
    const API_EmailDomain = 'authEmailDomain';
    const API_ProviderIds = 'authProviderIds';
    const UI_RejectedEmailMsg = 'This email address is incorrect.';
    const UI_ChangedEmailMsg = 'Another email address was used to sign in.';

    const storedTenant = localStorage.getItem(TENANT_STORAGE_KEY);

    const formEl = $('#form-container');
    const firebaseEl = $('#firebaseui-container');
    const verificationEl = $('#email-verification-container');
    const resetEl = $('#reset-login-flow-container');
    const resetUserIDEl = $('#login-flow-userid');
    const resetBtnEl = $('#login-flow-reset-btn');
    const emailInputEl = $('#input');
    const errorDisplayEl = $('#textfield');
    const errorMessageEl = $('#error');

    let fbAuthHandler;
    let ciapInstance;
    let email;

    verificationEl.hide();
    resetEl.hide();
    firebaseEl.hide();
    formEl.hide();

    $('#form-email').on("submit", clickHandler);

    let previousError = null;
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(URL_ERROR_MESSAGE_PARAM_KEY)) {
        errorDisplayEl.addClass('is-invalid');
        previousError = urlParams.get(URL_ERROR_MESSAGE_PARAM_KEY);
        errorMessageEl.text(previousError);
    }
    if (urlParams.has(URL_DEFAULT_EMAIL_PARAM_KEY)) {
        email = urlParams.get(URL_DEFAULT_EMAIL_PARAM_KEY);
        emailInputEl.val(email);
    }

    if (storedTenant === null || previousError !== null) {
        formEl.show();
        emailInputEl.focus();
    } else {
        const tenant = JSON.parse(storedTenant);
        firebaseEl.show();
        firebaseLogin(tenant);
    }

    // resetFBIDB is used to reset the Firebase Indexed DB and local storage after a bodged sign in attempt
    function resetFBIDB() {
        localStorage.removeItem(TENANT_STORAGE_KEY);
        localStorage.removeItem(FB_STORAGE_KEY);
        localStorage.clear();

        try {
            let DBOpenRequest = window.indexedDB.open(FB_IDB_NAME, 1);
            DBOpenRequest.onsuccess = function (event) {
                const db = DBOpenRequest.result;
                // open a read/write db transaction, ready for deleting the data
                let transaction = db.transaction([FB_IDB_OS_NAME], "readwrite");

                // create an object store on the transaction
                const objectStore = transaction.objectStore(FB_IDB_OS_NAME);

                // Make a request to delete the specified record out of the object store
                if (objectStore) {
                    objectStore.clear();
                    console.log("cleared firebase IDB");
                }

                // report on the success of the transaction completing, when everything is done
                transaction.oncomplete = function (event) {
                    console.log("reset firebase IDB complete");

                };
                transaction.onerror = function (event) {
                    console.log("failed to open firebase IDB:", event);
                };

                transaction.commit();
            };
        } catch (e) {
            console.warn("failed to reset firebase IDB: ", e);
        }
    }

    // resetHandler resets the Firebase Auth UI widget and start a new sign in session
    function resetHandler(resetURL, errMsg, defaultEmail) {
        console.log("resetting auth");

        resetEl.hide();
        resetBtnEl.unbind("click");

        firebaseEl.hide();
        verificationEl.hide();

        emailInputEl.val("");

        if (errMsg) {
            errorDisplayEl.addClass('is-invalid');
            errorMessageEl.text(errMsg);
        }

        firebase.auth().signOut().then(function () {
            console.log("reset sign out successful");
        }).catch(function (error) {
            console.log("reset sign out failed:", error);
        }).finally(function () {

            // reset local storage
            resetFBIDB();

            // reset FB auth
            try {
                if (fbAuthHandler) {
                    fbAuthHandler.reset();
                }
                const fbInstance = firebaseui.auth.AuthUI.getInstance(firebaseConfig.appId);
                if (fbInstance) {
                    fbAuthHandler.reset();
                }
            } catch (e) {
                console.warn("firebase UI reset failed: ", e);
            }
            fbAuthHandler = null;

            // show the email input form
            formEl.show();

            // the below is implemented to work around the difficulties in resetting the Firebase Auth UI
            // if the no-state sign in URL isn't specified then grab the current URL
            if (!resetURL) {
                resetURL = requestURL;
            }
            let url = new URL(resetURL);
            let params = new URLSearchParams(url.search);
            // inject error message into the reload URL
            if (params) {
                // don't override the mode
                if (params.has("mode")) {
                    params.delete("mode");
                }
                // params.append("mode", "selectAuthSession");
                if (params.has("nonce")) {
                    params.delete("nonce");
                }
                params.append("nonce", Math.floor(Math.random() * 1000000).toString());
                if (params.has(URL_ERROR_MESSAGE_PARAM_KEY)) {
                    params.delete(URL_ERROR_MESSAGE_PARAM_KEY);
                }
                // only add a error message if specified
                if (errMsg) {
                    params.append(URL_ERROR_MESSAGE_PARAM_KEY, errMsg);
                }
                if (params.has(URL_DEFAULT_EMAIL_PARAM_KEY)) {
                    params.delete(URL_DEFAULT_EMAIL_PARAM_KEY);
                }
                // only add a default email if specified
                if (defaultEmail) {
                    params.append(URL_DEFAULT_EMAIL_PARAM_KEY, defaultEmail);
                }
                url.search = "?" + params.toString();
            }

            console.log("reset via URL: ", url.toString());
            // force a reset of the page
            window.location.replace(url.toString());
            setTimeout(function () {
                window.location.reload(true);
            }, 500);
        });
    }

    function clickHandler(e) {
        const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const emailInput = emailInputEl.val();
        const $submitBtn = $("#form-button");
        $submitBtn.attr("disabled", "disabled");
        $submitBtn.addClass("disabled");

        const reenableSubmit = function () {
            $submitBtn.removeAttr("disabled");
            $submitBtn.removeClass("disabled");
        }

        if (re.test(emailInput)) {
            errorDisplayEl.removeClass('is-invalid');
            email = emailInput;
            fetch(`/api/v1/lookup-tenant?email=${encodeURIComponent(emailInput)}`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (data) {
                    data.email = emailInput;
                    data.resetUrl = requestURL;
                    localStorage.setItem(TENANT_STORAGE_KEY, JSON.stringify(data));
                    formEl.hide();
                    firebaseEl.show();
                    firebaseLogin(data);
                    reenableSubmit();
                })
                .catch(function (error) {
                    console.log("Error: ", error);
                    errorMessageEl.text(UI_RejectedEmailMsg);
                    errorDisplayEl.addClass('is-invalid');
                    reenableSubmit();
                });
        } else {
            errorMessageEl.text(UI_RejectedEmailMsg);
            errorDisplayEl.addClass('is-invalid');
            reenableSubmit();
        }

        e.preventDefault();
        return false;
    }

    function buildTenants(tenant) {
        let signInOpts = [];
        let ssoCount = 0;
        let isSSO = function (providerID) {
            return providerID.substr(0, 5) === "saml." || providerID.substr(0, 5) === "oidc.";
        };
        tenant[API_ProviderIds].forEach(function (providerID) {
            if (isSSO(providerID)) {
                ssoCount++;
            }
        });

        tenant[API_ProviderIds].forEach(function (providerID) {
            // prevent requirement to enter name
            if (providerID === firebase.auth.EmailAuthProvider.PROVIDER_ID) {
                signInOpts.push({
                    provider: providerID,
                    requireDisplayName: false,
                    fullLabel: "Email",
                });
                return;
            }
            // use the organization name when saml or oidc is in use
            if (isSSO(providerID)) {
                let fullLabel = "Single sign-on (SSO)";
                if (ssoCount > 1) {
                    fullLabel = tenant[API_TenantName] + " " + fullLabel;
                }
                signInOpts.push({
                    provider: providerID,
                    providerName: tenant[API_TenantName],
                    fullLabel: fullLabel,
                    // iconUrl: 'https://www.example.com/photos/my_idp/saml.png'
                });
                return;
            }
            signInOpts.push({
                provider: providerID
            });
        });
        const config = {};
        config[tenant[API_TenantId]] = {
            displayName: tenant[API_TenantName],
            signInFlow: "redirect",
            immediateFederatedRedirect: true,
            signInOptions: signInOpts
        };
        config["*"] = {
            displayName: "",
            signInFlow: "redirect",
            immediateFederatedRedirect: true,
            signInOptions: []
        };
        return config;
    }

    function firebaseLogin(data) {
        if (fbAuthHandler) {
            console.warn("not reinitialising without resetting");
            return;
        }

        let autoSignInEmailFiller = function (singleTry) {
            const $emailInput = $("#ui-sign-in-email-input");
            if ($emailInput.length > 0) {
                $emailInput.val(data.email);
                $emailInput.prop("disabled", true);
                let $submit = $(".firebaseui-id-submit");
                if ($submit.length > 0 && $submit.text() === "Next") {
                    $submit.first().click();
                    setTimeout(() => {
                        autoSignInEmailFiller(true);
                    }, 100);
                }

                let $cancel = $(".firebaseui-id-secondary-link");
                if ($cancel.length > 0) {
                    $cancel.remove();
                }
                return;
            }
            if (singleTry) {
                setTimeout(autoSignInEmailFiller, 100);
            }
        };

        const tenantId = data[API_TenantId];

        const configs = {
            [firebaseConfig.apiKey]: {
                authDomain: firebaseConfig.authDomain,
                displayMode: 'optionFirst',
                // identifierFirst for testing how Firebase UI does their single email auth
                //displayMode: 'identifierFirst',
                callbacks: {
                    // WARNING: signInSuccessWithAuthResult is replaced by the CIAP redirect
                    // and the below is not run by Firebase UI
                    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                        const user = authResult.user;
                        //var credential = authResult.credential;
                        //var isNewUser = authResult.additionalUserInfo.isNewUser;
                        //var providerId = authResult.additionalUserInfo.providerId;
                        //var operationType = authResult.operationType;

                        if (data !== null && user.email !== data.email) {
                            console.warn("signing in success with user " + user.email + " but user requested " + data.email + ", resetting session");
                            resetHandler(data.resetUrl, UI_ChangedEmailMsg, user.email);
                            return false;
                        }
                        // do not remember the SAML session
                        resetFBIDB();

                        if (redirectUrl) {
                            console.log("redirecting to ", redirectUrl);
                            window.location.assign(redirectUrl);
                            return false;
                        }

                        return true;
                    },
                    // see callbacks here: https://github.com/firebase/firebaseui-web/blob/master/firebaseuihandler/README.md#setting-up-callbacks
                    uiShown: function () {
                        console.log("ui shown: ", data.email);
                    },
                    beforeSignInSuccess: beforeSignIn,
                    selectTenantUiShown: function () {
                        const $btns = $('.firebaseui-id-tenant-selection-button');
                        // hide the non-relevant btns
                        $btns.filter(function () {
                            return $(this).attr('data-tenant-id') !== tenantId;
                        }).hide();
                        // select the tenant automatically
                        $btns.filter(function () {
                            return $(this).attr('data-tenant-id') === tenantId;
                        }).click();
                    },
                    signInUiShown: (tenantId) => {
                        // Show tenant title and additional display info.
                        console.log("signing into tenant: " + tenantId);
                        $(".firebaseui-id-page-provider-sign-in").prepend(`<div id="fb-signin-method-header" class="firebaseui-card-header"><h1 class="firebaseui-title">Choose sign-in method</h1></div>`);

                        const $btns = $('.firebaseui-id-idp-button');
                        $btns.each(function (idx, el) {
                            const $el = $(el);
                            // if we are signing in with email
                            if ($el.data("provider-id") === firebase.auth.EmailAuthProvider.PROVIDER_ID) {
                                // add a on click handler
                                $el.on("click", function () {
                                    console.log("choosing password method - auto filling email");
                                    setTimeout(autoSignInEmailFiller, 10);
                                });
                            }
                        });

                        // if there is only one choice then select it automatically
                        if ($btns.length === 1) {
                            const $first = $btns.first();
                            console.log("using provider automatically: " + $first.data("provider-id"));
                            $first.click();
                        } else if ($btns.length === 0) {
                            autoSignInEmailFiller(true);
                        }
                    }
                },
                tenants: buildTenants(data),
                tosUrl: 'https://nextdlp.com/msa',
                privacyPolicyUrl: 'https://nextdlp.com/privacy-policy',
            },
        };

        firebaseui.auth.tenantId = tenantId;
        firebaseui.auth.email = data.email;
        email = data.email;

        resetUserIDEl.text(data.email);
        resetBtnEl.click(function (e) {
            resetHandler(requestURL);
        });
        resetEl.show();

        // this cannot be NONE because it forgets about what the IAP said
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(function () {
                try {
                    // Existing and future Auth states are now persisted. Closing the window would clear any existing state even
                    // if a user forgets to sign out.
                    fbAuthHandler = new firebaseui.auth.FirebaseUiHandler('#firebaseui-container', configs);

                    ciapInstance = new ciap.Authentication(fbAuthHandler);
                    ciapInstance.start();

                    // disabled until we can override the firebase auth UI interface to CIAP
                    //     authInstance = fbAuthHandler.getAuth(firebaseConfig.apiKey, tenantId);
                    //     let prom = fbAuthHandler.startSignIn(authInstance, selectedTenantInfo);
                }catch (e) {
                    console.error("failed to initialise the sign in session: exception ", e);
                    resetHandler(requestURL);
                    return;
                }
            })
            .catch(function (error) {
                console.warn("failed to start the sign in session: ", error.code, " - ", error.message);
                resetHandler(requestURL);
            });
    }

    function errMsgChecker() {
        const $fbUIBanner = $(".firebaseui-info-bar-message");
        if ($fbUIBanner.length > 0) {
            const errorText = $fbUIBanner.text().toLowerCase();
            // Examples of error messages:
            // "The identity provider configuration is not found"
            // "Error when parsing certificate"
            console.debug("firebase info banner text: ", errorText);
            if (errorText.includes("not found") || errorText.includes("error")) {
                console.warn("sign in error detected, resetting", errorText);
                resetHandler(redirectUrl, `Configuration error: ${errorText}. Please contact Next DLP support for assistance.`, email);
                return;
            }
        }

        const $fbUIText = $(".firebaseui-text");
        let errorText = "";
        if ($fbUIText.length > 0) {
            errorText = $fbUIText.text();
            console.debug("firebase UI text: ", errorText);
            if (errorText.includes("Please visit the URL that redirected you to this page again to restart the authentication process.")) {
                console.warn("sign in session timeout detected, resetting", errorText);
                resetHandler(redirectUrl, "Sign in session timed out. Please start again.", email);
                return;
            }
            if (errorText.includes("Client specified an invalid argument.")) {
                console.warn("sign in error detected, resetting", errorText);
                resetHandler(redirectUrl, "The sign in service has encountered an error. Please try again.", email);
                return;
            }
        }

        const $fbTitle = $(".firebaseui-title");
        if ($fbTitle.length === 0) { // nothing to check yet
            return;
        }

        // if there has been an error, then reset
        const fbTitleText = $fbTitle.text().toLowerCase();
        // if there has been an error, then reset
        if (fbTitleText.includes("error encountered")) {
            console.warn("error encountered, resetting", errorText);
            resetHandler(redirectUrl, `Error occurred during sign in: ${errorText}. Please start again.`, email);
            return;
        }

        // if there are no options to choose from, then reset
        if (fbTitleText.includes("choose sign-in method") && $(".firebaseui-idp-list > li").length === 0) {
            console.warn("choose sign-in method has no options, resetting");
            resetHandler(redirectUrl, "Sign in session timed out. Please start again.", email);
            return;
        }
    }

    function checkForVerification(user, verified) {
        user.reload()
            .then(() => {
                if (user.emailVerified) {
                    resetEl.hide();
                    firebaseEl.show();

                    // if user sign in will be successful, clear the SAML cache
                    resetFBIDB();
                    verified(user);
                    return;
                }
                console.log("still checking email verification...");
                setTimeout(() => checkForVerification(user, verified), 5000);
            })
            .catch((e) => {
                console.log("failed to check verification email: ", e);
            });
    }

    function beforeSignIn(user) {

        resetUserIDEl.text(user.email);

        if (storedTenant !== null) {
            const localTenantInfo = JSON.parse(storedTenant);
            if (localTenantInfo !== null && user.email !== localTenantInfo.email) {
                console.warn("signing in with user " + user.email + " but user requested " + localTenantInfo.email + ", resetting session");
                resetHandler(redirectUrl, UI_ChangedEmailMsg, user.email);
                return false;
            }
        }

        if (user.emailVerified) {
            // if user sign in will be successful, clear the SAML cache
            resetFBIDB();
            return Promise.resolve(user);
        }

        firebaseEl.hide();
        resetEl.show();
        verificationEl.show();

        return new Promise(function (resolve, reject) {
            const data = {};
            data.email = user.email;

            let errMsg = "";

            user.getIdToken(/* forceRefresh */ true)
            .then(function(idToken) {
                data.token = idToken;
            })
            .then(() => {
                return fetch('/api/v1/email-confirmation', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    })
            })
            .then(function(response) {
                if (!response.ok) {
                    errMsg = `${response.status}`;
                    return response.text()
                }
                return Promise.resolve(null);
            })
            .then(function(result) {
                if (errMsg !== "") {
                    if (result) {
                        errMsg = errMsg + " " + result;
                    }
                    throw Error(errMsg);
                }
            })
            .then(() => {
                checkForVerification(user, resolve);
            })
            .catch((e) => {
                console.error("failed to send verification email before sign in: ", e);
                resetHandler(redirectUrl, "An error has occurred, please try again.");
            })
        });
    }
});
